
export const BaseUrl = "https://api.gaswizard.io/api/v1"

// export const BaseUrl = "http://192.168.1.40:8086/api/v1"







